<template>
  <div class="right-panel-wrap">
    <div class="car-order-detail-wrap">
      <div v-if="!showGoodsOrderCard" class="card-wrap">
        <div class="address-wrap">
          <div class="left-address">
            <div class="address">
              <div>{{ goodsOrderInfo.loadAddrName || "--" }}</div>
              <div>装 {{ goodsOrderInfo.loadEarlyTime | parseTime("MM-DD HH:mm") }}</div>
            </div>
            <div class="arrow-wrap">
              <div class="product">{{ goodsOrderInfo.goodsName || "--" }}</div>
              <img src="@/assets/images/monitor/arrow-right.svg">
              <div class="weight">{{ $parseNumber(goodsOrderInfo.total,1000) || "--" }}吨</div>
            </div>
            <div class="address">
              <div>{{ goodsOrderInfo.unloadAddrName || "--" }}</div>
              <div>卸 {{ goodsOrderInfo.unloadEarlyTime | parseTime("MM-DD HH:mm") }}</div>
            </div>
          </div>
          <div class="right-change">
            <div @click="changeGoodsOrder">
              <img src="@/assets/images/monitor/iconPark-switch.png">
            </div>
            <div>切换货单</div>
          </div>
        </div>
        <div class="box-title">
          <div class="left">履约执行中</div>
          <div class="right">
            <span>货单号：{{ goodsOrderInfo.goodsSerial || "--" }}</span>
            <img src="@/assets/images/monitor/copy.svg">
          </div>
        </div>
        <div class="box-card-wrap">
          <div v-for="(item,index) in statusList" :key="index" class="box-item-wrap">
            <el-popover
              placement="bottom"
              width="400"
              trigger="click"
              popper-class="popover-monitor-wrap"
              class="popover-wrap"
            >
              <div class="license-plate">
                <template v-if="carData[item.props].length">
                  <div v-for="(sitem, sindex) in carData[item.props]" :key="sindex" :class="['license-plate-item', { 'active-index': sitem.a === activeCarData.a }]" @click.stop="carDataClick(sitem)">{{ sitem.b }}</div>
                </template>
                <div v-else class="license-plate-item no-data">暂无</div>
              </div>
              <div slot="reference" class="flex">
                <img :src="require(`@/assets/images/monitor/${item.pic}.svg`)">
                <div class="item-data" :class="itemActive === 3 ? 'item-data-active' : ''">
                  <div class="item-type">{{ item.label }}</div>
                  <div class="item-num">{{ carNum[item.props] }}</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
        <Timeline :order-id="goodsOrderInfo.id" :type="+goodsOrderInfo.type===1?1.1:1.2" />
        <div class="tansport-total-wrap">
          <div>
            装<span>{{ $parseNumber(goodsOrderInfo.realLoadNum,1000) }}</span>吨
          </div>
          <div>
            卸<span>{{ $parseNumber(goodsOrderInfo.realUnLoadNum,1000) }}</span>吨
          </div>
          <div>
            损耗<span>{{ numberCalculation(goodsOrderInfo.realLoadNum,goodsOrderInfo.realUnLoadNum,1000,'minus') }}</span>吨
          </div>
        </div>
        <!-- goodsOrderInfo.error||showErrorTip -->
        <div v-if="goodsOrderInfo.error&&showError" class="error-tip">
          <div class="flex-c">
            <i class="iconfont icon-alert-fill error-icon" />
            <span>当前货单有{{ goodsOrderInfo.error }}车风险告警</span>
          </div>
          <i class="iconfont icon-close close-icon" @click="showError=false" />
        </div>
      </div>
      <div v-if="showGoodsOrderCard" class="doing-wrap">
        <div class="nav-tab-wrap">
          <div v-for="(item, index) in tabs" :key="index" :class="index === activeTab ? 'active-tab flex-c' : 'flex-c'">
            <span @click="changeTab(item,index)">{{ item.label }}</span>
          </div>
        </div>
        <div class="doing-list">
          <div v-for="(item, index) in goodsOrder" :key="index" class="list-item-wrap">
            <div class="create-time">{{ item.creatTime | parseTime }}</div>
            <div v-for="(sitem, sindex) in item.orderList" :key="sindex" class="content-wrap" @click="selectGoodsOrderOne(sitem)">
              <div class="top-wrap flex-b-c">
                <div v-if=" sitem.error" class="triangle"><span>{{ sitem.error }}</span></div>
                <div class="address">
                  <div>{{ sitem.loadAddrName||'--' }}</div>
                  <div>
                    <i class="iconfont icon-map-load-line main-c" />
                    <span>装 {{ sitem.loadEarlyTime | parseTime("MM-DD HH:mm") }}</span>
                  </div>
                </div>
                <div class="arrow-wrap">
                  <div class="product">{{ sitem.goodsName||'--' }}</div>
                  <img src="@/assets/images/monitor/arrow-right.svg">
                  <div class="weight">{{ sitem.total||'--' }}吨 {{ sitem.truckNum||'--' }}车</div>
                </div>
                <div class="address">
                  <div>{{ sitem.unloadAddrName||'--' }}</div>
                  <div>
                    <i class="iconfont icon-map-unload-line green-c" />
                    <span>卸 {{ sitem.unloadEarlyTime | parseTime("MM-DD HH:mm") }}</span>
                  </div>
                </div>
              </div>
              <div class="bottom-wrap">
                <div class="trans-weight"><span>{{ sitem.realLoadNum||'--' }}</span> 吨</div>
                <div class="car-num">
                  <i class="iconfont icon-tanker-line green-c" />
                  <span>{{ sitem.relTruckNum }}</span> 车
                </div>
                <div class="trans-weight"><span>{{ sitem.realUnLoadNum||'--' }}</span> 吨</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from './timeline';
import { numberCalculation, parseTime } from '@/utils';
export default {
  components: { Timeline },
  filters: {
    parseTime,
  },
  props: {
    // 货单列表
    goodsOrderList: {
      type: Array,
      default() {
        return [];
      },
    },
    allCarData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      numberCalculation,
      // new
      showGoodsOrderCard: false,
      statusList: [
        { label: '待执行', pic: 'car-kong', type: 1, props: 'dai' },
        { label: '执行中', pic: 'car-zhong', type: 2, props: 'zhong' },
        { label: '已完成', pic: 'car-out', type: 3, props: 'finish' },
        { label: '告警', pic: 'car-error', type: 4, props: 'error' },
      ],
      tabs: [
        { label: '履约中', value: 1 },
        { label: '历史履约', value: 2 },
      ],
      activeTab: 0,
      goodsOrder: [], // 货单列表
      goodsOrderInfo: {
        // loadAddrName: '成都', loadEarlyTime: 1720599180000, unloadAddrName: '重庆', unloadEarlyTime: 1721030400000, goodsName: '盐酸', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 4, error: 3,
      }, // 当前货单信息
      showError: true, // 展示高级
      carNum: {
        // all: 0,
        zhong: 0,
        dai: 0,
        // out: 0,
        finish: 0,
        error: 0,
      },
      carData: {
        // all: [],
        zhong: [],
        dai: [],
        finish: [],
        error: [],
      },
      carDataList: [],
      activeCarData: {},
      itemActive: null,
    };
  },

  computed: {
  },

  watch: {
    goodsOrderList(val) {
      console.log(val, 'goodsOrderListval');
      this.goodsOrder = JSON.parse(JSON.stringify(val));
      this.goodsOrderInfo = this.goodsOrder[0] || {};
      console.log(this.goodsOrderInfo, 'this.goodsOrderInfo');
    },
    allCarData(val) {
      console.log(val, 'val');
      this.transformCarData(val);
    },
  },

  created() {
  },

  mounted() {},

  methods: {
    // 处理数据 分类归纳
    transformCarData(carDataList) {
      for (let i = 0; i < carDataList.length; i++) {
        if (+carDataList[i].c === 0) {
          this.carNum.dai++;
          this.carData.dai.push(carDataList[i]);
        } else if (+carDataList[i].c === 1) {
          this.carNum.zhong++;
          this.carData.zhong.push(carDataList[i]);
        } else if (+carDataList[i].c === 2) {
          this.carNum.finish++;
          this.carData.finish.push(carDataList[i]);
        } else if (+carDataList[i].c === 3) {
          this.carNum.error++;
          this.carData.error.push(carDataList[i]);
        }
      }
    },
    // 切换货单
    changeGoodsOrder() {
      this.showGoodsOrderCard = true;
      this.getGoodsOrderList();
    },
    // 获取货单列表（履约中 历史履约）
    getGoodsOrderList() {
      this.goodsOrder = [
        { creatTime: 1721030400000, orderList: [
          { loadAddrName: '成都', loadEarlyTime: 1720599180000, unloadAddrName: '重庆', unloadEarlyTime: 1721030400000, goodsName: '盐酸', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 4, error: 3, id: 394, type: 1 },
          { loadAddrName: '成都2', loadEarlyTime: 1720599180000, unloadAddrName: '重庆2', unloadEarlyTime: 1721030400000, goodsName: '盐酸2', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 3, error: 0, id: 397, type: 1 },
          { loadAddrName: '成都3', loadEarlyTime: 1720599180000, unloadAddrName: '重庆3', unloadEarlyTime: 1721030400000, goodsName: '盐酸3', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 1, error: 1, id: 398, type: 1 },
        ] },
        { creatTime: 1721030400000, orderList: [
          { loadAddrName: '成都', loadEarlyTime: 1720599180000, unloadAddrName: '重庆', unloadEarlyTime: 1721030400000, goodsName: '盐酸', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 4, error: 3, id: 399, type: 2 },
          { loadAddrName: '成都2', loadEarlyTime: 1720599180000, unloadAddrName: '重庆2', unloadEarlyTime: 1721030400000, goodsName: '盐酸2', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 3, error: 0, id: 340, type: 1 },
          { loadAddrName: '成都3', loadEarlyTime: 1720599180000, unloadAddrName: '重庆3', unloadEarlyTime: 1721030400000, goodsName: '盐酸3', goodsSerial: 'G202407101613320007', total: 11000, truckNum: 34, realLoadNum: 53560, realUnLoadNum: 54545, relTruckNum: 1, error: 1, id: 341, type: 1 },
        ] },
      ];
    },
    // 切换tab  调取货单列表
    changeTab(data, i) {
      this.activeTab = i;
      this.getGoodsOrderList(data.value);
    },
    // 点击具体得货单 展示货单运输详细面板且根据货单id 调取该货单下关联车子（车单）
    selectGoodsOrderOne(data) {
      console.log(data, 'data');
      this.showGoodsOrderCard = false;
      this.goodsOrderInfo = data;
      // 选择货单后 货单数据传递给主页
      this.$emit('selectGoodsOrder', data);
      // this.getCarList(data.id);
    },
    // d点击具体车辆
    carDataClick(item) {
      this.activeCarData = item;
      this.$emit('rightShowCarLog', item);
    },
    timeComputed(a = new Date().getTime(), b) {
      if (a && b) {
        const sdate = new Date(a); // 结束时间
        const now = new Date(b); // 开始时间
        const endTime = sdate.getTime(); // 结束时间
        const startTime = now.getTime(); // 开始时间
        const timeDiff = endTime - startTime;
        let hours = parseInt((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = parseInt((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = (timeDiff % (1000 * 60)) / 1000;
        hours < 10 ? (hours = '0' + hours) : hours; // 小时格式化
        minutes < 10 ? (minutes = '0' + minutes) : minutes; // 分钟格式化
        seconds < 10 ? (seconds = '0' + seconds) : seconds; // 秒钟格式化

        const k = hours + '小时' + minutes + '分钟';
        // return k;
        if (seconds < '0') {
          return '--';
        } else {
          return k;
        }
      } else {
        return '--';
      }
    },
    returnColor() {
      if (this.dialogMarkerData.status === '运输中') {
        return '#10CE66';
      } else if (this.dialogMarkerData.status === '空闲') {
        return '#1A6AFF';
      } else if (this.dialogMarkerData.status === '离线') {
        return '#58595C';
      } else {
        return '#04122b';
      }
    },
    returnBackground() {
      if (this.dialogMarkerData.status === '运输中') {
        return 'rgba(16, 206, 102, 0.1)';
      } else if (this.dialogMarkerData.status === '空闲') {
        return 'rgba(26, 106, 255, 0.1)';
      } else if (this.dialogMarkerData.status === '离线') {
        return 'rgba(88, 89, 92, 0.1)';
      } else {
        return 'rgba(4,18,43, 0.1)';
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.right-panel-wrap{
  // background: #fff;
  // padding: 15px;
  // border-radius: 8px;
  // font-size: 14px;
  .car-order-detail-wrap {
    position: absolute;
    top: 10px;
    right: 12px;
    .card-wrap {
      // min-width: 430px;
      width: 520px;
      min-height: 57px;
      background: #ffffff;
      border-radius: 4px;
      padding: 17px 20px 20px 20px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      .address-wrap {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(99,102,108,0.12);
        .left-address{
          flex:1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .address {
            width: 180px;
            text-align: center;
            >div{
              &:first-child{
                font-size: 24px;
              }
              &:last-child{
                color: rgba(21,24,29,0.64);
                margin-top: 10px;
              }
            }
          }
          .arrow-wrap{
            text-align: center;
            .product,.weight{
              font-size: 14px;
              color: rgba(21,24,29,0.64);
            }
            .product{
              margin-bottom: -10px;
            }
            .weight{
              margin-top: -5px;
            }
            img{
              width: 60px;
            }
          }
        }
        .right-change{
          width: 93px;
          background: #E6EDFF;
          margin: -17px -20px -5px 0;
          border-top-right-radius: 2px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          >div{
            cursor: pointer;
            &:first-child{
              height: 40px;
              width: 40px;
              background: #fff;
              border-radius: 40px;
              box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.14);
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover{
                scale: 1.05;
              }
            }
            &:last-child{
              margin-top: 2px;
              color: rgba(21,24,29,0.6);
              font-size: 12px;
            }
          }
        }
      }
      .box-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          color: rgba(16,16,16,1);
          font-size: 16px;
        }
        .right{
          display: flex;
          align-items: center;
          font-size: 12px;
          color: rgba(0,0,0,0.4);
          img{
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
      .box-card-wrap {
        min-height: 74px;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        display: flex;
        // justify-content: space-between;
        .box-item-wrap{
          width: 25%;
          display: flex;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;
          .popover-wrap{
            width: 100%;
          }
          img{
            width: 30px;
          }
          .item-data{
            flex: 1;
            text-align: center;
            .item-type{
              font-size: 14px;
              color: #58595c;
            }
            .item-num{
              font-size: 20px;
              font-weight: 600;
              color: #26272a;
            }
            &:hover {
              .item-type,
              .item-num {
                color: #1a6aff;
              }
            }
          }
          &::after {
            width: 1px;
            height: 30px;
            content: "";
            background: rgba(0, 0, 0, 0.1);
            // margin-right: 10px;
          }
        }
        >:last-child{
          margin-right: 0;
          &::after {
            display: none;
          }
        }
      }
      .tansport-total-wrap{
        padding: 10px 25px;
        display: flex;
        justify-content: space-between;
        color: rgba(21,24,29,0.64);
        >div{
          span{
            color: #101010;
            margin: 0 3px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .error-tip{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px 17px;
        background: #FFE5DE;
        border-radius: 3px;
        color: rgba(21,24,29,0.69);
        .error-icon{
          font-size: 20px;
          color: #FF1313;
          margin-right: 10px;
        }
        .close-icon{
          font-size: 15px;
          color: #FF1313;
          cursor: pointer;
          // transition: scale 0.1s ease;
          &:hover{
            scale: 1.1;
          }
        }
      }
    }
    .doing-wrap{
      width: 520px;
      min-height: 57px;
      // background: #F7F7F7;
      background: #fff;
      border-radius: 4px;
      padding: 17px 20px 20px 20px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      .nav-tab-wrap{
        display: flex;
        margin-bottom: 15px;
        >div{
          margin-right: 50px;
          font-size: 16px;
          cursor: pointer;
        }
        .active-tab {
          color:#101010;
          font-weight: bold;
          border-radius: 2px 2px 0px 0px;
          position: relative;
        }
        .active-tab:after {
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: rgba(67, 133, 255, 1);
          position: absolute;
          border-radius: 1px;
          bottom: -5px;
        }
      }
      .doing-list{
        font-size: 16px;
        overflow-y: scroll;
        max-height:calc(100vh - 150px);
        .list-item-wrap{
          background: #F7F7F7;
          padding: 10px;
          // margin-bottom: 20px;
          .create-time{
            color: rgba(0,0,0,0.4);
            margin-bottom: 13px;
          }
          .content-wrap{
            // display: flex;
            cursor: pointer;
            padding: 5px;
            background: #fff;
            border-radius: 8px;
            margin-bottom: 7px;
            .top-wrap{
              position: relative;
              .triangle{
                position: absolute;
                right: 0;
                top: -10px;
                width: 0;
                height: 0;
                border: 11px solid transparent;
                border-bottom: 16px solid #F43C32;
                display: flex;
                justify-content: center;
                >span{
                  font-size: 12px;
                  color:#fff;
                }
              }
              .address {
                // width: 195px;
                flex: 1;
                text-align: center;
                >div{
                  &:nth-child(1){
                    font-size: 24px;
                  }
                  &:nth-child(2){
                    color: rgba(21,24,29,0.64);
                    margin-top: 10px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                      font-size: 18px;
                    }
                  }
                }
              }
              .arrow-wrap{
                text-align: center;
                .product,.weight{
                  font-size: 14px;
                  color: rgba(21,24,29,0.64);
                }
                .product{
                  margin-bottom: -10px;
                }
                .weight{
                  margin-top: -5px;
                }
                img{
                  width: 60px;
                }
              }
            }
            .bottom-wrap{
              display: flex;
              justify-content: space-between;
              color: rgba(21,24,29,0.6);
              font-size: 14px;
              >div{
                // flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                  font-size: 16px;
                  font-weight: bold;
                  color: #101010;
                  margin: 0 2px;
                }
              }
              .trans-weight{
                flex: 1;
              }
              .car-num{
                flex: 0.65;
              }
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    // .process-wrap {
    //   display: flex;
    //   padding-bottom: 10px;
    //   margin-bottom: 15px;
    //   border-bottom: 1px solid #ecedf0;
    //   .statistics-wrap {
    //     display: flex;
    //     align-items: center;
    //     flex: 1;
    //     .statistics-item {
    //       width: 200px;
    //       color: #fff;
    //       display: flex;
    //       margin-left: 10px;
    //       > span {
    //         padding: 2px;
    //         display: inline-block;
    //         text-align: center;
    //         font-size: 12px;
    //       }
    //       .item1 {
    //         background: #2773ff;
    //       }
    //       .item2 {
    //         background: #07c160;
    //       }
    //       .item3 {
    //         background: #63686e;
    //       }
    //     }
    //   }
    //   .weight-wrap {
    //     color: #58595c;
    //     span {
    //       font-size: 16px;
    //     }
    //   }
    // }
    // .product-weight,
    // .time-wrap {
    //   display: flex;
    //   align-items: center;
    //   margin-top: 5px;
    //   color: #58595c;
    //   .icon {
    //     color: #737478;
    //     font-size: 16px;
    //     margin-right: 8px;
    //   }
    // }
    // .product-weight {
    //   > div {
    //     display: flex;
    //     align-items: center;
    //     span {
    //       // padding: 0 5px;
    //       &::after {
    //         content: "";
    //         display: inline-block;
    //         width: 1px;
    //         height: 11px;
    //         background: #dbdbdb;
    //         margin: 0 10px;
    //       }
    //     }
    //     :last-child {
    //       &::after {
    //         display: none;
    //       }
    //     }
    //     .product-name-wrap {
    //       display: inline-block;
    //       max-width: 280px;
    //       white-space: nowrap;
    //       text-overflow: ellipsis;
    //       overflow: hidden;
    //     }
    //   }
    // }
    // .time-wrap {
    //   .line {
    //     margin: 0 10px;
    //   }
    // }
    // .car-info-detail-wrap {
    //   .car-info {
    //     display: flex;
    //     .top-car-che {
    //       width: 88px;
    //       line-height: 28px;
    //       text-align: center;
    //       border-radius: 4px;
    //       background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
    //       background-size: 100% 100%;
    //       font-size: 14px;
    //       color: #04122b;
    //       margin-right: 10px;
    //     }
    //     .top-car-type {
    //       line-height: 28px;
    //       padding: 0 12px;
    //       background: rgba(16, 206, 102, 0.1);
    //       border-radius: 4px;
    //       color: #10ce66;
    //       font-size: 14px;
    //       font-weight: 400;
    //       position: relative;
    //       margin-right: 30px;
    //       .status-text {
    //         // width: 42px;
    //         text-align: center;
    //       }
    //     }
    //   }
    //   .people-company-info {
    //     margin-top: 10px;
    //     color: #58595c;
    //     .compamy-wrap {
    //       margin-bottom: 5px;
    //       display: flex;
    //       align-items: center;
    //       i {
    //         font-size: 20px;
    //         margin-right: 6px;
    //       }
    //       span {
    //         font-weight: bold;
    //       }
    //     }
    //     .people-wrap {
    //       display: flex;
    //       > div {
    //         display: flex;
    //         align-items: center;
    //         width: 50%;
    //         i {
    //           font-size: 20px;
    //           margin-right: 6px;
    //         }
    //       }
    //     }
    //   }
    // }
    // .time-line-wrap {
    //   .km {
    //     display: flex;
    //     align-items: center;
    //     margin-bottom: 10px;
    //     svg {
    //       font-size: 20px;
    //       margin-right: 8px;
    //     }
    //     > div {
    //       display: flex;
    //       flex: 1;
    //       width: 100%;
    //       > div {
    //         width: 47%;
    //         display: flex;
    //         flex-direction: column;
    //         > span {
    //           font-weight: bold;
    //         }
    //         .label {
    //           color: #58595c;
    //           font-weight: normal;
    //         }
    //       }
    //     }
    //   }
    //   .s-line {
    //     width: 1px;
    //     height: 35px;
    //     border: 1px solid #10ce66;
    //   }
    //   .s-line-h {
    //     width: 1px;
    //     height: 35px;
    //     border: 1px solid #c0c4cc;
    //   }
    //   .label-text {
    //     font-size: 16px;
    //     font-weight: bold;
    //   }
    //   .w-line-show {
    //     // height: calc(100vh - 500px);
    //     // max-height: 342px;
    //     height: calc(100vh - 640px);
    //     max-height: 285px;
    //     overflow-y: scroll;
    //     > div {
    //       padding: 5px 0;
    //       margin-left: -14px;
    //     }
    //     .el-col-3 {
    //       text-align: center;
    //     }
    //     span {
    //       color: #272727;
    //       &.w-time {
    //         color: #58595c;
    //       }
    //     }
    //     .sign-wrap {
    //       > div {
    //         display: flex;
    //         flex-direction: column;
    //       }
    //       span {
    //         color: #58595c;
    //       }
    //     }
    //     .right-wrap {
    //       position: relative;
    //       &::before {
    //         content: "";
    //         position: absolute;
    //         display: block;
    //         width: 1px;
    //         border-left: 1px solid #c0c4cc;
    //         top: 17px;
    //         margin-left: -24.5px;
    //         height: calc(100% - 4px);
    //         // height: 100%;
    //         background: transparent;
    //       }
    //     }
    //     .green-line {
    //       &::before {
    //         border-left: 1px solid #10ce66;
    //       }
    //     }
    //   }
    //   .last-item {
    //     .right-wrap {
    //       position: relative;
    //       &::before {
    //         display: none;
    //       }
    //     }
    //   }
    // }
    // .title {
    //   font-weight: 600;
    //   color: #26272a;
    //   line-height: 24px;
    //   font-size: 16px;
    // }
    // .detail-wrap {
    //   padding: 10px 0;
    //   color: #666;
    //   line-height: 24px;
    //   .black-text {
    //     color: #26272a;
    //   }
    // }
  }
}
.popover-monitor-wrap{
  // width: 100%;
  .license-plate{
    max-height: 628px;
    // padding: 12px 12px 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .license-plate-item {
      display: inline-block;
      margin-right: 5px;
      width: 82px;
      line-height: 36px;
      text-align: center;
      background: #f3f4f7;
      border-radius: 2px;
      margin-bottom: 8px;
      cursor: pointer;
    }
    .active-index {
      background: #1a6aff;
      color: #ffffff;
    }
  }
}
</style>
