
import axios from 'axios';
import { debounce } from '@/config/settings';
import store from '@/store';

// 判断当前url是否需要加loading

const needLoading = (config) => {
  let status = false;
  debounce.forEach((item) => {
    if (config.url.includes(item)) {
      status = true;
    }
  });
  return status;
};

const requestTimeout = 30000;

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: requestTimeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    // let baseURL = process.env.VUE_APP_BASE_API;
    // console.log(baseURL, 'baseURL');
    // if (config.url.includes('/prod-api/pass')) {
    //   baseURL = '/';
    // }
    // 添加统一头部
    // config.headers.product_id = config.productId ? config.productId : productId;

    // store.getters['user/accessToken'] && (config.headers.token = store.getters['user/accessToken']);

    // store.getters['user/userId'] && (config.headers.user_id = store.getters['user/userId']);

    // store.getters['user/userInfo'] && (config.headers.cpy_id = store.getters['user/userInfo'].companyId);
    config.headers.product_id = config.productId ? config.productId : 2025;
    store.getters['user/accessToken'] && (config.headers.token = store.getters['user/accessToken'] || '8C3495BF00068AFA');

    store.getters['user/userId'] && (config.headers.user_id = store.getters['user/userId'] || '428794');

    store.getters['user/userInfo'] && (config.headers.cpy_id = store.getters['user/userInfo'].companyId || '17');

    if (needLoading(config)) {
      // loadingInstance = Vue.prototype.$baseLoading();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
