<template>
  <div class="left-carrier-wrap">
    <el-select v-model="companyValue" class="company-select" placeholder="全部承运商" clearable @change="changeCompany">
      <el-option
        v-for="item in companyList"
        :key="item.id"
        :label="item.userName"
        :value="item.id"
        class="company-list-options"
      >
        <div class="list-wrap flex-b-c">
          <UserInfoCard class="user-info-card" :allow-to-detail="false" :user-info="item" />
          <div class="user-info-card">{{ item.carNum }} 车</div>
        </div>
      </el-option>
    </el-select>
    <div class="input-car">
      <el-button class="car-button" icon="el-icon-search" type="primary" @click="searchMark" />
      <el-select v-model="carValue" filterable clearable placeholder="请输入车牌号" :popper-append-to-body="false" @focus="resultList">
        <el-option v-for="item in carList" :key="item.a" :label="item.b" :value="item.b" />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentGoodsOrder: {
      type: Object,
      default() {
        return {};
      },
    },
    allCarData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      companyList: [
        { id: 1, titleLogo: '6d4120f1bbae4ad8badb4d8d52b09386', titleName: 'www', titlePassFlag: 1, userName: '王济航', userPassFlag: 1, carNum: 3 },
        { id: 2, titleLogo: '6d4120f1bbae4ad8badb4d8d52b09386', titleName: 'ccc', titlePassFlag: 0, userName: '2王济航', userPassFlag: 0, carNum: 2 },
      ],
      companyValue: '',
      carList: [
        // { b: '苏AE5340', a: 2361 },
        // { b: '苏AGDE89', a: 1857 },
        // { b: '苏AE5411', a: 2362 },
        // { b: '苏AE8453', a: 2364 },
        // { b: '苏AE8471', a: 2365 },
      ],
      carValue: '',
    };
  },

  computed: {},
  watch: {
    currentGoodsOrder(val) {
      if (val) {
        // 拿到当前最新的货单id 获取该货单下所有的承运商
        this.getWayBillUserList(val.id);
      }
    },
  },
  created() {
    // console.log(this.allCarData, 'carData');
  },

  mounted() {},

  methods: {
    // 获取承运商列表
    getWayBillUserList(id) {
      // mock
      this.companyList = [
        { id: 1, titleLogo: '6d4120f1bbae4ad8badb4d8d52b09386', titleName: 'www', titlePassFlag: 1, userName: '王济航', userPassFlag: 1, carNum: 3 },
        { id: 2, titleLogo: '6d4120f1bbae4ad8badb4d8d52b09386', titleName: 'ccc', titlePassFlag: 0, userName: '2王济航', userPassFlag: 0, carNum: 2 },
        { id: 3, titleLogo: '6d4120f1bbae4ad8badb4d8d52b09386', titleName: 'ccc', titlePassFlag: 0, userName: '3王济航', userPassFlag: 0, carNum: 4 },
      ];
    },
    // 选择承运商
    changeCompany(e) {
      // console.log(e, 'e');
      // 拿到选择得承运商id 调取接口获取关联车辆
      this.getCompanyCar(e);
    },
    // 车辆e
    getCompanyCar(companyId) {
      if (companyId) {
        this.carList = [
          { b: '苏AE5340', a: 2361 },
          { b: '苏AGDE89', a: 1857 },
        ];
      }
      // else {
      //   this.carList = [
      //     { b: '苏AE5340', a: 2361 },
      //     { b: '苏AGDE89', a: 1857 },
      //     { b: '苏AE5411', a: 2362 },
      //     { b: '苏AE8453', a: 2364 },
      //     { b: '苏AE8471', a: 2365 },
      //   ];
      // }
    },
    resultList() {
      this.carList = Object.freeze([...this.allCarData.values()]);
      // console.log(this.carList, 'this.carList');
    },
    searchMark() {
      this.$emit('leftSearchMark', this.carValue);
    },
  },
};

</script>
<style lang='scss' scoped>
.left-carrier-wrap{
  display: flex;
}
.company-select{
  width: 280px;
  margin-right: 10px;
}
.input-car {
  display: flex;
  width: 280px;
  // position: absolute;
  // top: 20px;
  // left: 20px;
  ::v-deep.el-input-group__append,
  .el-input-group__prepend {
    background-color: #409eff;
    color: #fff;
  }
}
.company-list-options{
  height: 40px;
  .list-wrap{
    line-height: 16px;
    padding: 5px 0;
    // .user-info-card{
      // padding: 3px 0;
    // }
  }
}
</style>
