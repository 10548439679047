<template>
  <div class="add-panel">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMaps">
      <!-- id="fullMap" -->
      <div v-loading="loading" class="fullMap">
        <!-- :style="{ height: !fullscreen ? '610px' : '100vh' }" -->
        <div id="mapBig" ref="mapBig" class="map fullMap" :style="{ height: !fullscreen ? 'calc(100vh - 176px)' : '100vh' }" />
        <img :class="['full-img', fullscreen ? 'all-unfull' : 'all-full']" :src="require(`@/assets/images/monitor/${fullscreen ? 'allunfull' : 'allfull'}.svg`)" @click="screen">
        <RightPanel :car-detail="carDetail" :dialog-marker-data="dialogMarkerData" />
      </div>
    </fullscreen>
  </div>
</template>

<script>
import vehicleBill from '@/api/pound';
import RightPanel from './rightPanel';
import AMapLoaderLass from '@amap/amap-jsapi-loader';
import { getCarPostionNew, getCarTrackByIdNew, getCarTrackNew } from '@/api/transport/monitor/transportMap';
import { parseTime } from '@/utils';
import pako from 'pako';
export default {
  components: { RightPanel },
  filters: {
    parseTime,
  },
  props: {
    carId: {
      type: Number,
      default() {
        return 0;
      },
    },
    carInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    // orderInformation: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },
  data() {
    return {
      carDetail: {}, // 车单详情信息
      geocoder: null,
      lnglatList: [], // 轨迹不加装卸货点经纬度集合
      loading: false,
      loadTableData: null, // 装货起点信息
      unloadTableData: null, // 卸货终点信息
      allCarLocationList: [], // 所有车辆的车牌和经纬度信息 便于地图打点显示车辆
      fullscreen: false,
      dialogMarkerData: {
        speed: 0, // 当前车速
        residueDistance: 0, // 剩余里程
        residueDriverTime: 0, // 预计剩余时长
        address: '', // 当前所在地
        driverDistance: 0, // 实际行驶里程
        driverTime: 0, // 实际行驶时间
      },
      // distanceCar: 0, // 今日里程
      // clickCarNo: '',
      isXie: false,
    };
  },
  computed: {
    // ...mapGetters({
    // userInfo: 'user/userInfo',
    // }),
    // carData() {
    //   return [
    //     {
    //       carOrderId: this.carDetail.id,
    //       carNo: this.carDetail.truckNo,
    //       trailerNo: this.carDetail.guaNo,
    //       driverName: this.carDetail.driverName,
    //       escortName: this.carDetail.escortName,
    //       // startTime: this.orderInformation.newTime,
    //       // endTime: 1637225683256,
    //       status: this.carDetail.state,
    //       clicked: true,
    //     },
    //   ];
    // },
  },
  watch: {
    // 'this.orderInformation.id'(val){
    //   console.log(val,'val');
    // }
    carId(val) {
      console.log(val, 'val---watch======================');
      // this.init();
    },
  },

  created() {
  },
  mounted() {
    // console.log(1111);
    // this.getCarTrackDataNew({ a: '苏BH1190', b: 1627729920674, c: 1631069591000 });
    this.initMap();
  },
  methods: {
    initMap() {
      AMapLoaderLass.load({
        key: '2ce614857c44af9c3cc5f2691e537ed9', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Geocoder', 'AMap.Driving'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2', // Loca 版本，缺省 1.3.2
        },
      })
        .then((AMap) => {
          this.mapInstance = new AMap.Map('mapBig');
          this.AMap = AMap;
          this.geocoder = new AMap.Geocoder();
          this.getCarData();
          window.myMap = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 获取车单详情数据
    async getCarData() {
      // id 订单id carOrderId 车单id, historyId 历史车单id
      this.loading = true;
      // 627 464
      vehicleBill.getList({ id: 627 })
        .then(async(res) => {
          this.carDetail = res || {};
          await this.loadCarData(this.carDetail);
          await this.loadedAddress(this.carDetail.carAddressVOList);
        })
        .finally(() => {
          // 有了车辆信息后 根据车辆信息加载地图信息
          this.loading = false;
          this.drawInitCar();
        });
    },
    // 根据车牌 获取车得实时位置
    async loadCarData(data) {
      // console.log(data.truckNo, 'data-根据车牌 获取车得实时位置');
      // const allCarNoList = ['苏AE8471', '苏BH1191', '苏BH1192'];
      // const allCarNoList = ['苏AE8471'];
      // const allCarNoList = ['苏A9B047'];
      const allCarNoList = ['苏AM4001'];
      // 苏AE8471
      // // 根据车牌号 获取车的实时位置
      this.allCarLocationList = await this.getCarLocationData(allCarNoList);
      // console.log(this.allCarLocationList, 'this.allCarLocationList');
    },
    // 获取所有车的实时位置 目前场景单车单 仅一辆车
    getCarLocationData(carList) {
      return new Promise((resolve, reject) => {
        // axios
        //   .post("/demo/pass/carnumber/lgt?md=016&cmd=041", { a: carList }, { responseType: "arraybuffer" })
        getCarPostionNew({ a: carList }, { responseType: 'arraybuffer' })
          .then((response) => {
            if (response.data) {
              const carLocationData = this.parseBetyData(response.data);
              // console.log(carLocationData, 'carLocationData');
              let resolveData = [];
              if (carLocationData.d && carLocationData.d.length !== 0) {
                resolveData = carLocationData.d;
              } else {
                resolveData = [];
              }
              resolve(resolveData);
            }
          })
          .catch(function(error) {
            console.log('error:', error);
            reject(error);
          })
          .finally(() => {
            // this.switchCar(this.carData[0]);
          });
      });
    },
    // 装卸点信息
    loadedAddress(data) {
      this.loadTableData = data.filter((val) => +val.type === 1);
      this.unloadTableData = data.filter((val) => +val.type === 2);
    },
    drawInitCar() {
      if (this.allCarLocationList.length) {
        const firstCarLocation = this.allCarLocationList[0] || {};
        console.log(firstCarLocation, 'firstCarLocation');
        // 获取当前车速
        this.dialogMarkerData.speed = firstCarLocation.d || 0;
        // 获取当前地址
        this.getAddress([firstCarLocation.b / 1000000, firstCarLocation.c / 1000000]).then((res) => {
          this.dialogMarkerData.address = res;
        });
        // 获取剩余公里数
        if (this.unloadTableData[0] && this.unloadTableData[0].addrPoint) {
          const residueDistance = this.loadCarLineNew([firstCarLocation.b / 1000000, firstCarLocation.c / 1000000], [this.unloadTableData[0].addrPoint.split(',')[0] || 0, this.unloadTableData[0].addrPoint.split(',')[1] || 0]);
          this.dialogMarkerData.residueDistance = residueDistance;
          // 预计剩余时长(剩余距离/速度)
          const H = this.getTimeH(this.dialogMarkerData.residueDistance, this.dialogMarkerData.speed);
          const M = this.getTimeM(this.dialogMarkerData.residueDistance, this.dialogMarkerData.speed);
          this.dialogMarkerData.residueDriverTime = `${H}h ${M}m`;
        }
      }
      if (this.mapInstance) {
        this.mapInstance.clearMap(); // 清空画布
        // 画起点 终点
        this.loadTableData && this.drawMarker(this.loadTableData[0], 0);
        this.unloadTableData && this.drawMarker(this.unloadTableData[0], 1);
        // 根据车辆数据加载地图车实时位置
        this.allCarLocationList.map((item) => {
          this.drawCarMark(item.a, `${item.b / 1000000},${item.c / 1000000}`);
        });
        // console.log(this.loadTableData, 'this.loadTableData');
        // 根据装卸货经纬度 绘制导航规划线路
        this.drawPlanLine(this.loadTableData[0].addrPoint, this.unloadTableData[0].addrPoint);
        this.mapInstance.setFitView();
      }
      this.switchCar(this.carDetail);
    },
    async switchCar(item) {
      this.isXie = false;
      this.getCarInfoData({
        carOrderId: item.id,
        carNo: item.truckNo,
        driverId: item.driverId,
        status: item.state,
      });
    },
    getCarInfoData(params) {
      let startTime;
      let endTime;
      // 1派单（接单） 5到装 7装货 8到卸 9卸货
      // 开始时间取上传装货磅单时间 7
      // 结束时间取卸货磅单时间 9 如果未完成卸货 则取此刻时间
      this.zhuangData = this.carDetail.carNodeVOList.filter((val) => +val.nodeType === 7);
      this.xieData = this.carDetail.carNodeVOList.filter((val) => +val.nodeType === 9);
      if (this.zhuangData.length) {
        startTime = +this.zhuangData[0].nodeTime;
      }
      if (this.xieData.length) {
        this.isXie = true;
        endTime = +this.xieData[0].nodeTime;
      }
      // 修改绘制轨迹规则  endTime 有卸货节点则取卸货节点时间 无使用当前时间
      if (!this.isXie) {
        endTime = new Date().getTime();
      }
      const diff = endTime - startTime;
      // 已经行驶的时间
      console.log(diff, 'diff');
      const ress = this.timeComputed(startTime, endTime);
      const timestamp1 = Date.now(); // 当前时间戳
      const timestamp2 = timestamp1 + 1000 * 60 * 60 * 24; // 比当前时间戳多一小时
      console.log(this.timeComputed(timestamp1, timestamp2), 'dfsf');
      console.log(ress, 'ress');
      this.loadTableData && this.drawMarker(this.loadTableData[0], 0);
      this.unloadTableData && this.drawMarker(this.unloadTableData[0], 1);
      if (diff > 10 * 60 * 1000) {
      // 压缩的方式获取
        this.getCarTrackDataNew(
          {
            a: params.carNo ? params.carNo : undefined,
            b: startTime, // cmd080 f节点 取派车时间
            c: endTime || new Date().getTime(), // 优先取卸货》卸货打卡》当前时间
          // d: 2,
          },
          {
            a: params.driverId,
            b: startTime, // cmd080 f节点 取派车时间
            c: endTime || new Date().getTime(), // 优先取卸货》卸货打卡》当前时间
            d: 2,
          },
          params.status,
        );
      }
    },
    getCarTrackDataNew(data, data2, status) {
      data = { a: '苏AE8471', b: 1721873614870, c: 1721903399514 };
      // data2 = { a: '苏BH1190', b: 1627729920674, c: 1631069591000 };
      // data2 = { a: 411452, b: 0, c: 1631235138367 };
      // 获取轨迹
      this.loading = true;
      // 2000条压缩轨迹数据
      let list;
      // axios
      //   .post("/demo/pass/carnumber/lgt?md=016&cmd=040", data, { responseType: "arraybuffer", headers: { user_id: 1001, userId: 1001 } })
      getCarTrackNew(data, { responseType: 'arraybuffer' })
        .then((response) => {
          if (response.data) {
            // 0 不压缩 1 解压
            // 处理数据
            const mapData = this.parseBetyData(response.data);
            // const mapData = {
            //   d: [
            //     { b: 118913544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118915167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118916587, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118916449, c: 32156860, d: 0, e: 0, f: 190 },
            //     { b: 118915497, c: 32152690, d: 26, e: 0, f: 191 },
            //     { b: 118914591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118916710, c: 32146132, d: 0, e: 0, f: 40 },
            //     { b: 118915927, c: 32147118, d: 14, e: 0, f: 341 },
            //     { b: 118915909, c: 32147036, d: 0, e: 0, f: 173 },
            //     { b: 118916322, c: 32146713, d: 0, e: 0, f: 78 },
            //     { b: 118914156, c: 32142998, d: 38, e: 0, f: 221 },
            //     { b: 118313544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118415167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118516587, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118616449, c: 32156860, d: 0, e: 0, f: 190 },
            //     { b: 117154973, c: 32152690, d: 26, e: 0, f: 191 },
            //     { b: 118814591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118656710, c: 32146132, d: 0, e: 0, f: 40 },
            //     { b: 118545927, c: 32147118, d: 14, e: 0, f: 341 },
            //     { b: 115615909, c: 32147036, d: 0, e: 0, f: 173 },
            //     { b: 113216322, c: 32146713, d: 0, e: 0, f: 78 },
            //     { b: 118567156, c: 32142998, d: 38, e: 0, f: 221 },
            //     { b: 118988544, c: 32167918, d: 0, e: 0, f: 214 },
            //     { b: 118885167, c: 32167104, d: 19, e: 0, f: 133 },
            //     { b: 118918887, c: 32162128, d: 24, e: 0, f: 187 },
            //     { b: 118915649, c: 32136860, d: 0, e: 0, f: 190 },
            //     { b: 118915497, c: 32122690, d: 26, e: 0, f: 191 },
            //     { b: 118914591, c: 32143158, d: 18, e: 0, f: 0 },
            //     { b: 118916710, c: 32142132, d: 0, e: 0, f: 40 },
            //     { b: 118915927, c: 32143118, d: 14, e: 0, f: 341 },
            //     { b: 118915909, c: 32142036, d: 0, e: 0, f: 173 },
            //     { b: 118916322, c: 32141713, d: 0, e: 0, f: 78 },
            //     { b: 118914156, c: 32142498, d: 38, e: 0, f: 221 },
            //   ],
            // };
            if (mapData.d && mapData.d.length !== 0) {
              list = mapData.d.map((val) => {
                return {
                  lnglat: `${val.b / 1000000},${val.c / 1000000}`, // 经纬度
                };
              });
              // 轨迹不加装卸货点
              this.lnglatList = [...list];
              this.drawLine(status);
            } else {
              // 第一个接口没数据 请求第二个接口
              // axios
              //   .post("/demo/pass/carnumber/lgt?md=016&cmd=042", data2, { responseType: "arraybuffer", headers: { user_id: 1001, userId: 1001 } })
              getCarTrackByIdNew(data2, { responseType: 'arraybuffer' })
                .then((response) => {
                  // console.log("%c  42轨迹 response: ", "font-size:20px;background-color: #4b4b4b;color:#fff;", response);
                  if (response.data) {
                    // 0 不压缩 1 解压
                    // 处理数据
                    const mapData = this.parseBetyData(response.data);
                    const data = mapData.d || [];
                    list = data.map((val) => {
                      return {
                        lnglat: val.c, // 经纬度
                        createTime: val.e, // 上报时间
                        count: val.d, // 重复次数
                      };
                    });
                    // 轨迹不加装卸货点
                    this.lnglatList = [...list];
                    this.drawLine(status);
                  }
                })
                .catch((err) => err);
            }
          }
        })
        .catch((error) => {
          console.log('error:', error);
        });
    },
    // 重载里程
    loadCarLine(ring) {
      const line = AMap.GeometryUtil.distanceOfLine(ring);
      return line;
    },
    screen() {
      this.fullscreen = !this.fullscreen;
    },
    drawCarMark(carNo, carLngLat) {
      const markHtml = `<div class="bg-car">${carNo}</div>`;
      const marker = new AMap.Marker({
        position: new AMap.LngLat(...carLngLat.split(',')),
        icon: require('@/assets/images/monitor/car.png'), // 添加 Icon 图标 URL
        title: `${carNo}`,
      });
      // 将 markers 添加到地图
      this.mapInstance.add(marker);
      // 设置label标签
      marker.setLabel({
        content: markHtml, // 设置文本标注内容
        direction: 'top', // 设置文本标注方位
      });
    },
    parseBetyData(betyData) {
      // 0 不压缩 1 解压
      try {
        const dataBf = betyData.slice(1, betyData.byteLength); // 数据buff
        const typeBfView = new Int8Array(betyData, 0, 1);
        let jsonStr;
        if (typeBfView[0] === 0) {
          const byteArray = new Uint8Array(dataBf);
          jsonStr = new TextDecoder().decode(byteArray);
        } else {
          const data = pako.inflate(dataBf);
          jsonStr = new TextDecoder().decode(data);
        }
        return JSON.parse(jsonStr);
      } catch (e) {
        return [];
      }
    },
    drawLine(status) {
      if (!this.AMap) return;
      // 轨迹绘画
      const lnglatList = this.lnglatList || [];
      const PolylineList = lnglatList.map((item) => {
        return item.lnglat.split(',');
      });
      // 测试数据
      // PolylineList = [
      //   [116.478912, 39.998549],
      //   [116.478998, 39.998555],
      //   [116.478998, 39.998555],
      //   [116.479282, 39.99856],
      //   [116.479658, 39.998528],
      //   [116.480151, 39.998453],
      //   [116.480784, 39.998302],
      //   [116.480784, 39.998302],
      //   [116.481149, 39.998184],
      //   [116.481573, 39.997997],
      //   [116.481863, 39.997846],
      //   [116.482072, 39.997718],
      //   [116.482362, 39.997718],
      //   [116.483633, 39.998935],
      //   [116.48367, 39.998968],
      //   [116.484648, 39.999861],
      // ];
      new this.AMap.Polyline({
        map: this.mapInstance,
        path: PolylineList,
        showDir: true,
        strokeColor: '#00BD02', // 线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid"  //线样式
      });
      const line = this.loadCarLine(PolylineList); // 获取已行驶公里数
      // this.distanceCar = parseFloat(line / 1000).toFixed(2);
      // console.log(this.distanceCar, 'this.distanceCar');
      // // this.carDetail.distanceCar = this.distanceCar;
      this.dialogMarkerData.driverDistance = parseFloat(line / 1000).toFixed(2);
      let lastPoint;
      const item = this.loadTableData[0];
      // const item1 = this.unloadTableData[0];
      // const item = { lnglat: "116.478935,39.997761" }; // 测试数据
      // const item1 = { addrPoint: '116.484648, 39.999861' }; // 测试数据
      if (this.lnglatList.length === 0) {
        lastPoint = item.addrPoint;
      } else {
        lastPoint = this.lnglatList[this.lnglatList.length - 1].lnglat;
      }
      this.drawMarker({ lnglat: lastPoint }, 2);
      // if (![5, 6, 7].includes(status)) {
      //   this.drawLastLine(lastPoint, item1.addrPoint);
      // }
      this.mapInstance.setFitView();
      this.loading = false;
    },
    drawPlanLine(startLnglat, terminalLnglat) {
      if (startLnglat && terminalLnglat) {
        this.driving = new AMap.Driving({
          map: this.mapInstance,
          policy: this.AMap.DrivingPolicy.LEAST_TIME,
          hideMarkers: true,
          showTraffic: false,
          // outlineColor: "red",
        });
        // this.driving.search([{ keyword: this.startAddress }, { keyword: this.terminalAddress }]);
        const start = [+startLnglat.split(',')[0], +startLnglat.split(',')[1]];
        const end = [+terminalLnglat.split(',')[0], +terminalLnglat.split(',')[1]];
        this.driving.search(start, end);
      }
    },
    drawLastLine(startLnglat, terminalLnglat) {
      if (startLnglat && terminalLnglat) {
        this.driving = new AMap.Driving({
          map: this.mapInstance,
          policy: this.AMap.DrivingPolicy.LEAST_TIME,
          hideMarkers: true,
          showTraffic: false,
          // outlineColor: "red",
        });
        // this.driving.search([{ keyword: this.startAddress }, { keyword: this.terminalAddress }]);
        const start = [+startLnglat.split(',')[0], +startLnglat.split(',')[1]];
        const end = [+terminalLnglat.split(',')[0], +terminalLnglat.split(',')[1]];
        // this.driving.search(start, end);
        this.driving.search(start, end, (status, reslut) => {
          if (status === 'complete') {
            const { routes = [] } = reslut;
            const { steps = [] } = routes[0];
            const pathArr = [];
            steps.map((item) => {
              pathArr.push(item.path);
              return pathArr;
            });
            const path = this.flatten(pathArr);

            const polyline = new this.AMap.Polyline({
              map: this.mapInstance,
              path,
              showDir: true,
              strokeColor: '#c9cacc', // 线颜色
              strokeOpacity: 1, // 线透明度
              strokeWeight: 6, // 线宽
              strokeStyle: 'solid', // 线样式
              lineJoin: 'round', // 折线拐点的绘制样式
              zIndex: 999,
            });
            polyline.setMap(this.mapInstance);
          }
        });
      }
    },
    flatten(arr) {
      const myarr = [];
      arr.map((item) => {
        item.map((point) => {
          const currentPoint = [point.lng, point.lat];
          myarr.push(currentPoint);
        });
      });
      return myarr;
    },
    drawMarker(data, type) {
      // type:0-开始 1-结束 2-中间汽车点
      const marker = new this.AMap.Marker();
      if (+type === 0) {
        const startIcon = new this.AMap.Icon({
          size: new this.AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new this.AMap.Size(135, 40),
          imageOffset: new this.AMap.Pixel(-7, -3),

          // size: new this.AMap.Size(32, 33),
          // image: require('@/assets/images/monitor/car-zhuang.svg'),
          // imageSize: new this.AMap.Size(32, 33),
          // imageOffset: new this.AMap.Pixel(0, 0),
        });
        marker.setIcon(startIcon);
      } else if (+type === 1) {
        const endIcon = new this.AMap.Icon({
          size: new this.AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new this.AMap.Size(135, 40),
          imageOffset: new this.AMap.Pixel(-97, -3),

          // size: new this.AMap.Size(32, 33),
          // image: require('@/assets/images/monitor/car-xie.svg'),
          // imageSize: new this.AMap.Size(32, 33),
          // imageOffset: new this.AMap.Pixel(0, 0),
        });
        marker.setIcon(endIcon);
      } else if (+type === 2) {
        // 如果已卸货 则不展示行车图标+车牌号
        if (!this.isXie) {
          const carIcon = new this.AMap.Icon({
            // size: new this.AMap.Size(25, 34),
            size: new this.AMap.Size(24, 24),
            image: require('@/assets/images/monitor/carPng2.png'),
            imageSize: new AMap.Size(24, 24),
            // imageOffset: new this.AMap.Pixel(-97, -3),
          });
          marker.setIcon(carIcon);
          const markHtml = `<div class="bg-car">${this.clickCarNo}</div>`;
          marker.setLabel({
            content: markHtml, // 设置文本标注内容
            direction: 'top', // 设置文本标注方位
          });
        }
      }
      if (data.addrPoint) {
        const lnglat = new this.AMap.LngLat(...data.addrPoint.split(','));
        marker.setPosition(lnglat);
        if (+type !== 2) {
          this.mapInstance.add(marker);
        } else {
          // 如果已卸货 则不展示行车图标+车牌号
          if (!this.isXie) {
            this.mapInstance.add(marker);
          }
        }
      }
      this.mapInstance.setFitView();
    },
    // 根据经纬度获取当前车辆位置
    getAddress(lnglat) {
      return new Promise((resolve, reject) => {
        this.geocoder.getAddress(lnglat, (status, result) => {
          let address;
          if (status === 'complete' && result.regeocode) {
            address = result.regeocode.formattedAddress;
            resolve(address);
          } else {
            console.log('根据经纬度查询地址失败');
            address = '未知道路';
            reject(address);
          }
        });
      });
    },
    // 获取两点之间的里程
    loadCarLineNew(startlnglat, endlnglat) {
      const ring = [startlnglat, endlnglat];
      const line = AMap.GeometryUtil.distanceOfLine(ring);
      return parseFloat(line / 1000).toFixed(1);
    },
    getTimeH(distence, speed) {
      if (!distence || +speed === 0) {
        return '--';
      } else {
        const time = (distence / speed).toFixed(2);
        console.log(time, 'time');
        if (parseInt(time) === 0) {
          return '00';
        } else if (parseInt(time) > 0 && parseInt(time) < 10) {
          return '0' + parseInt(time);
        } else {
          return parseInt(time);
        }
      }
    },
    getTimeM(distence, speed) {
      if (!distence || +speed === 0) {
        return '--';
      } else {
        const time = (distence / speed).toFixed(2);
        const minute = Math.round((Number(time.substring(time.length - 2)) / 100) * 60);
        if (parseInt(minute) === 0) {
          return '00';
        } else if (parseInt(minute) > 0 && parseInt(minute) < 10) {
          return '0' + parseInt(minute);
        } else {
          return parseInt(minute);
        }
      }
    },
    timeComputed(sTimeStamp, endTimeStamp) {
      console.log(sTimeStamp, endTimeStamp);
      if (sTimeStamp && endTimeStamp) {
        // const sdate = new Date(a); // 结束时间
        // const now = new Date(b); // 开始时间
        // const endTime = sdate.getTime(); // 结束时间
        // const startTime = now.getTime(); // 开始时间
        const timeDiff = endTimeStamp - sTimeStamp;
        console.log(timeDiff, 'timeDiff');
        // let days = parseInt((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        // let hours = parseInt((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        // let minutes = (timeDiff % (1000 * 60)) / 1000;
        // let seconds = (timeDiff % (1000)) / 1000;
        // days < 10 ? (days = '0' + days) : days; // 天数格式化
        // hours < 10 ? (hours = '0' + hours) : hours; // 小时格式化
        // minutes < 10 ? (minutes = '0' + minutes) : minutes; // 分钟格式化
        // seconds < 10 ? (seconds = '0' + seconds) : seconds; // 秒钟格式化
        // const k = days + '天' + hours + '小时' + minutes + '分钟' + seconds + '秒';
        // // return k;
        // if (seconds < '0') {
        //   return '--';
        // } else {
        //   return k;
        // }
        let day = parseInt(timeDiff / 86400000);
        let hour = parseInt((timeDiff % 86400000) / 3600);
        let minute = parseInt((timeDiff % 3600000) / 60);

        day = day ? (day + '天') : '';
        hour = hour ? (hour + '时') : '';
        minute = minute ? (minute + '分') : '';
        console.log(day + hour + minute, 'day + hour + minute;');
        return day + hour + minute;
      } else {
        return '--';
      }
    },
  },
};
</script>
<style lang="scss">
.amap-marker-label {
  border: none;
  background: none;
}
.bg-car {
  height: 26px;
  width: 84px;
  text-align: center;
  font-size: 14px;
  color: #04122b;
  line-height: 26px;
  // background: #0d1b36;
  // background: #4385ff;
  background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 2px;
}
</style>
<style lang="scss" scoped>
.fullMaps {
  // position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.c-tool {
  color: #c0c4cc;
  font-size: 16px;
  i {
    margin-right: 10px;
    cursor: pointer;
  }
}
.load-icon {
  font-size: 24px;
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #dcdfe6;
  margin-right: 10px;
}
.circleactive {
  background: #4385ff;
  // margin-right: 10px;
}
.map {
  width: 100%;
  height: 360px;
  max-height: 100%;
}
.map-full {
  color: #4385ff;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 50px;
  z-index: 1;
  cursor: pointer;
}
.fullMap {
  position: relative;
  width: 100%;
  // height: 610px;
  // height:100vh;
  padding: 0;
  margin: 0;
}
.w-dui {
  font-size: 15px;
  color: #c0c4cc;
}
.bg-more {
  &.bg {
    background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.6) 70%, rgb(255, 255, 255, 1) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.3) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.6) 70%, rgb(255, 255, 255, 1) 100%);
  }

  position: absolute;
  left: 0;
  bottom: 0;
  max-height: 100%;
  width: 100%;
  height: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 16px;
  color: #4385ff;
  span {
    cursor: pointer;
  }
}
.my-panel-body {
  position: relative;
  overflow: hidden;
}
.full-img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.all-full {
  top: -44px;
  right: 40px;
  &:hover {
    opacity: 0.9;
  }
}
.no-car {
  display: flex;
  justify-content: center;
  padding: 10px;
  color: #666;
}
.item-show {
  position: absolute;
  right: 20px;
  top: 11.11vh;
  padding: 20px;
  overflow-y: hidden;
  width: 300px;
  background: white;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0 0 4px 4px;
  .w-line-content {
    height: calc(100vh - 480px);
    max-height: 300px;
    overflow-y: scroll;
  }
  .load-content {
    width: 272px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #04122b;
  }
  .w-content {
    display: inline-block !important;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5a6073;
  }
  .w-item2 {
    display: inline-block !important;
    margin-left: 15px;
    font-size: 15px !important;
    font-weight: bold;
  }
  .w-item3 {
    display: inline-block !important;
    margin-left: 20px;
    font-size: 15px !important;
    font-weight: bold;
  }
  .w-line {
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    margin: 10px 0;
  }
  .w-item {
    display: block;
    height: 20px;
    line-height: 20px;
    color: #5a6073;
    font-size: 12px;

    &.w-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      color: #04122b;
      // height: 45px;
      // line-height: 45px;
      margin-bottom: 5px;
    }
    &.w-blue {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4385ff;
    }
  }
  .number-wrap {
    > div {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
  .driver-list {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    > div {
      i {
        font-size: 20px;
        margin-right: 4px;
      }
      &:first-child {
        margin-right: 52px;
      }
    }
  }
  .s-line {
    width: 1px;
    height: 35px;
    border: 1px solid #10ce66;
  }
  .s-line-h {
    width: 1px;
    height: 35px;
    border: 1px solid #c0c4cc;
  }
  .w-line-show {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    .el-col-3 {
      text-align: center;
    }
    span {
      color: #272727;
      &.w-time {
        color: #b0b3bd;
      }
    }
  }
}
</style>
