<template>
  <div class="timeline">
    <div v-for="(n) in processNodesData" :key="n.nodeType" :class="['timeline-item',+timelineDetail[n.prop]>0?'active':'']">
      <div class="timeline-item_wrap">
        <div class="title">
          {{ n.label }}
          <span v-if="timelineDetail[n.prop]>0" class="num">{{ timelineDetail[n.prop] }}</span>
        </div>
      </div>
      <div class="timeline-item_icon">
        <span class="quan" />
      </div>
    </div>
  </div>
</template>

<script>
import processNodes from '@/api/processNodes';
import { parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    orderId: {
      type: Number,
      default() {
        return 0;
      },
    },
    // 三种类型 货单（1.1定价 1.2竞价） 运单2 车单3
    type: {
      type: [String, Number],
      default() {
        return 1.1;
      },
    },
  },
  data() {
    return {
      processNodesDataMap: {
        1.1: [
          { label: '确认', prop: 'confirmCount' },
          { label: '到装', prop: 'loadSignCount' },
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          { label: '卸货', prop: 'unloadCount' },
          // { label: '磅单核定', prop: 'finish' },
          // { label: '已完成', prop: 'end' },
        ],
        1.2: [
          { label: '车数', prop: 'carCount' }, // 竞价多这个节点
          { label: '确认', prop: 'confirmCount' },
          { label: '到装', prop: 'loadSignCount' },
          { label: '装货', prop: 'loadCount' },
          { label: '到卸', prop: 'unloadSignCount' },
          { label: '卸货', prop: 'unloadCount' },
          // { label: '磅单核定', prop: 'finish' },
          // { label: '已完成', prop: 'end' },
        ],
      },
      timelineDetail: {
        carCount: 0,
        confirmCount: 0,
        loadSignCount: 0,
        loadCount: 0,
        unloadSignCount: 0,
        unloadCount: 0,
        finish: 0,
        end: 0,
        pushTme: 0,
      },
    };
  },
  computed: {
    processNodesData() {
      return this.processNodesDataMap[this.type];
    },
  },
  watch: {
    orderId: {
      handler(val) {
        this.getData();
      },
      immediate: true,
    },
  },
  created() {
    // this.getData();
  },
  methods: {
    async getData() {
      try {
        const apiTypeMap = {
          1.1: 'goodsProcess',
          1.2: 'goodsProcess',
          // 2: 'transProcess',
          // 3: 'carOrderProcess',
        };
        const timelineDetail = await processNodes[apiTypeMap[this.type]]({ id: this.orderId });
        if (timelineDetail.keyMap) {
          this.timelineDetail = timelineDetail.keyMap;
        }
      } catch (error) {
        console.log(error, 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  display: flex;
  padding-left: 25px;
  .timeline-item {
    flex: 1;
    .timeline-item_wrap {
      .title {
        font-size: 14px;
        color: #c0c4cc;
        margin-left: -8px;
        .num {
          font-size: 12px;
          color: #5a6073;
        }
      }
    }
    .timeline-item_icon {
      width: 12px;
      height: 12px;
      background: #c0c4cc;
      border: 1px solid #c0c4cc;
      color: #c0c4cc;
      border-radius: 50%;
      line-height: 12px;
      text-align: center;
      i {
        font-size: 16px;
      }
    }
    &::after{
      margin-top: -7px;
      display: block;
      content: '';
      height: 2px;
      width: 100%;
      background: #c0c4cc;
    }
    &:last-child{
      flex: 0.6;
      &::after{
        display: none;
      }
    }
  }
  .active {
    .timeline-item_icon {
      color: #10ce66;
      border-color: #10ce66;
      background: #10ce66;
    }
    .timeline-item_wrap {
      .title {
        color: #04122b;
      }
    }
    &::after{
      background: #10ce66;
    }
  }
}
</style>
